import React from "react";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Slider,
  Typography,
  Box,
} from "@mui/material";
import { squreStyle } from "./radiusStyle";
import {
  getBreakPointsValue,
  getCustomizationValue,
} from "../../../helper/theme";
import useWindowResize from "../../../hooks/useWindowResize";
const BANNER_SPACING_KEYS = ["top", "left", "right", "bottom"];

const BannerSpacing = (props) => {
  const { value: val, data, onChange, elementProps, classes } = props;
  const { key } = data;
  const lockKeyVal = `lock${key}`;
  let lockSpacing = elementProps[lockKeyVal];
  if (lockSpacing === undefined) {
    lockSpacing = true;
  }
  const [size] = useWindowResize();
  const value = getBreakPointsValue(val, size?.device);

  const handleChange = (e) => {
    let changeAll = {};
    if (lockSpacing) {
      BANNER_SPACING_KEYS.forEach((m) => {
        changeAll[m] = e.target.value;
      });
    }
    onChange({
      [key]: {
        ...getBreakPointsValue(val, null),
        [size?.device]: {
          ...value,
          ...changeAll,
          [e.target.name]: e.target.value,
        },
      },
    });
  };

  const onLockSpacing = () => {
    onChange({ [lockKeyVal]: !lockSpacing });
  };

  return (
    <Grid item xs={12}>
      <Typography
        variant="body1"
        color={"primary"}
        style={{ fontSize: "14px", fontWeight: 500 }}
      >
        {data?.label}
      </Typography>

      <Grid container wrap="nowrap" className="sld-wrpr">
        <Slider
          sx={{ opacity: !lockSpacing && 0.5 }}
          disabled={!lockSpacing}
          className="spacingSlider"
          defaultValue={value?.top || 0}
          aria-label="Default"
          valueLabelDisplay="auto"
          max={100}
          name="top"
          onChange={handleChange}
        />
        <Box
          component="input"
          sx={classes.sapcingInput}
          name="top"
          value={!lockSpacing ? "" : getCustomizationValue(value?.top)}
          className="sliderInput removeScroll"
          disabled={!lockSpacing}
          onChange={handleChange}
          type="number"
          placeholder="0"
        />
      </Grid>

      <FormControlLabel
        className="ccheckbox-primary"
        control={
          <Checkbox
            value={!lockSpacing}
            checked={!lockSpacing}
            onChange={onLockSpacing}
          />
        }
        label={
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            Customize
          </Typography>
        }
      />
      {!lockSpacing ? (
        <Grid container padding={4}>
          <Grid
            item
            xs={12}
            sx={{
              pb: 2,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100px",
                height: "100px",
                backgroundColor: "transparent",
                position: "relative",
                display: "flex",
              }}
            >
              <div className="bannerSpaceBox">
                <div className="bannerSpaceBoxTop">
                  <input
                    type="number"
                    name="top"
                    value={getCustomizationValue(value?.top)}
                    className="borderInput removeScroll"
                    style={{
                      ...squreStyle.topRight,
                    }}
                    onChange={handleChange}
                    placeholder="0"
                  />
                </div>
                <div className="bannerSpaceBoxRight">
                  <input
                    type="number"
                    name="right"
                    value={getCustomizationValue(value?.right)}
                    className="borderInput removeScroll"
                    style={{
                      ...squreStyle.bottomLeft,
                    }}
                    onChange={handleChange}
                    placeholder="0"
                  />
                </div>
                <div className="bannerSpaceBoxBottom">
                  <input
                    type="number"
                    name="bottom"
                    value={getCustomizationValue(value?.bottom)}
                    className="borderInput removeScroll"
                    style={{
                      ...squreStyle.bottomRight,
                    }}
                    onChange={handleChange}
                    placeholder="0"
                  />
                </div>
                <div className="bannerSpaceBoxLeft">
                  <input
                    type="number"
                    name="left"
                    className="borderInput removeScroll"
                    value={getCustomizationValue(value?.left)}
                    style={{
                      ...squreStyle.topLeft,
                    }}
                    onChange={handleChange}
                    placeholder="0"
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default BannerSpacing;
