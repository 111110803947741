import React, { useState } from "react";
import { Transforms } from "slate";
import { useSelected, useSlateStatic, ReactEditor } from "slate-react";
import AccordionBtnPopup from "./AccordionBtnPopup";
import { IconButton, Tooltip, Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridSettingsIcon } from "../../common/iconslist";
import Icon from "../../common/Icon";
import { useEditorSelection } from "../../hooks/useMouseMove";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const accordionBtnStyleKeys = {
  accordionTextColor: "textColor",
  accordionBgColor: "bgColor",
  accordionBorderColor: "borderColor",
};

const getAccordionData = (updateData) => {
  const accordionBtnStyle = {}; // accordion btn style will come under type: accordion node
  const accordionTitleStyle = {}; // accordion title style will come under type: accordion-summary node

  Object.entries(updateData).forEach(([key, value]) => {
    const accordionBtnStyleKey = accordionBtnStyleKeys[key];

    if (accordionBtnStyleKey) {
      // converting accordion button elementProp keys to node keys e.g: accordionTextColor -> textColor
      accordionBtnStyle[accordionBtnStyleKey] = value;
      return;
    }

    accordionTitleStyle[key] = value;
  });

  return {
    accordionBtnStyle,
    accordionTitleStyle,
  };
};

const convertAccordionBtnStyleKeys = (data = {}) => {
  const style = { ...data };

  Object.entries(accordionBtnStyleKeys).forEach(([key, value]) => {
    const val = data[value];

    if (val) {
      // deleting the existing style key in node e.g: textColor
      delete style[value];

      // convertint into new key in element props e.g: accordionTextColor
      style[key] = val;
    }
  });

  return style;
};

const getElementProps = (element) => {
  const accordionSummary = element.children?.find(
    (c) => c.type === "accordion-summary"
  );

  // joining accordion title and button styles
  const elementProps = {
    ...accordionSummary,
    ...convertAccordionBtnStyleKeys(element),
  };

  return elementProps;
};

const Accordion = (props) => {
  const { attributes, children, element, customProps } = props;
  const { readOnly } = customProps;
  const [toggle, setToggle] = useState(false);
  const [openSetttings, setOpenSettings] = useState(false);
  const editor = useSlateStatic();
  const [showTool] = useEditorSelection(editor);
  const selected = useSelected();
  const path = ReactEditor.findPath(editor, element);
  const { textColor, bgColor } = element;

  const onToggle = () => {
    setToggle(!toggle);
  };

  const ToolBar = () => {
    return selected && !showTool ? (
      <div
        className="element-toolbar hr"
        contentEditable={false}
        style={{
          top: "-32px",
          left: "0px",
          display: "flex",
          width: "fit-content",
        }}
      >
        <Tooltip title="Settings" arrow>
          <IconButton size="small" onClick={onSettings}>
            <GridSettingsIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete" arrow>
          <IconButton size="small" onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </div>
    ) : null;
  };

  const onSettings = () => {
    setOpenSettings(true);
  };

  const onDelete = () => {
    Transforms.removeNodes(editor, { at: path });
  };

  const setNodes = (data, path) => {
    Transforms.setNodes(editor, data, {
      at: path,
    });
  };

  const onSave = (data) => {
    const updateData = { ...data };

    const { accordionBtnStyle, accordionTitleStyle } =
      getAccordionData(updateData);

    // applying accordion title style
    const accordionSummary = data.children?.find(
      (c) => c.type === "accordion-summary"
    );
    const accordionSummaryPath = ReactEditor.findPath(editor, accordionSummary);

    setNodes(
      {
        ...accordionTitleStyle,
        type: "accordion-summary",
        children: accordionSummary.children,
      },
      accordionSummaryPath
    );

    // applying accordion button style
    delete accordionBtnStyle.children;

    setNodes(
      {
        ...accordionBtnStyle,
      },
      path
    );

    onClose();
  };

  const onClose = () => {
    setOpenSettings(false);
  };

  return (
    <div
      className={`accordion-container`}
      {...attributes}
      style={{ position: "relative" }}
    >
      <div
        className="accordion-title"
        style={{ background: bgColor }}
        onClick={onToggle}
      >
        <Box
          role="button"
          className="accordion-summary-collapse-btn"
          contentEditable={false}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            userSelect: "none",
            "& svg": {
              fill: textColor,
            },
            cursor: "pointer",
          }}
        >
          {!toggle ? (
            <Icon icon={"accordionArrow"} />
          ) : (
            <Icon icon={"accordionArrowDown"} />
          )}
        </Box>
        {children[0]}
      </div>
      <div
        className="accordion-content"
        style={{
          display: toggle ? "block" : "none",
        }}
      >
        {children[1]}
      </div>
      {!readOnly && <ToolBar />}
      {openSetttings ? (
        <AccordionBtnPopup
          element={getElementProps(element)}
          onSave={onSave}
          onClose={onClose}
          customProps={customProps}
        />
      ) : null}
    </div>
  );
};

export default Accordion;
