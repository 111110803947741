import React, { useState } from "react";
import { useSlateStatic } from "slate-react";
import PageSettingsPopup from "./PageSettingsPopup";
import { PageSettings } from "../../common/iconslist";
import ToolbarIcon from "../../common/ToolbarIcon";
import { getPageSettings, updatePageSettings } from "../../utils/pageSettings";

const PageSettingsButton = (props) => {
  const { customProps, icoBtnType, from, closePopper, setToolTip } = props;
  const [openSetttings, setOpenSettings] = useState(false);
  const editor = useSlateStatic();

  const onSettings = () => {
    const { element, path } = getPageSettings(editor);
    setOpenSettings({ element: element?.pageProps || {}, path });
  };

  const onSave = (data) => {
    try {
      const updateData = { ...data };
      delete updateData.children;
      updatePageSettings(editor, updateData);
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  const onClose = () => {
    closePopper(true);
    setToolTip(false)
    setOpenSettings(false); 
  };

  return (
    <>
      {from === "miniToolBar" ? (
        <PageSettings onClick={onSettings} className="removeDefaultSvgCls" />
      ) : (
        <ToolbarIcon
          title={"Page Settings"}
          onClick={onSettings}
          icon={<PageSettings />}
          icoBtnType={icoBtnType}
        />
      )}

      {openSetttings !== false ? (
        <PageSettingsPopup
          element={openSetttings?.element || {}}
          onSave={onSave}
          onClose={onClose}
          customProps={customProps}
        />
      ) : null}
    </>
  );
};

export default PageSettingsButton;
