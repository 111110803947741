import React, { useState } from "react";
import { Transforms } from "slate";
import { useSelected, useSlateStatic } from "slate-react";
import {
  Box,
  IconButton,
  Tooltip,
  Table as TableComp,
  TableBody,
} from "@mui/material";
import AlignHorizontalLeftIcon from "@mui/icons-material/AlignHorizontalLeft";
import AlignHorizontalRightIcon from "@mui/icons-material/AlignHorizontalRight";
import AlignVerticalTopIcon from "@mui/icons-material/AlignVerticalTop";
import AlignVerticalBottomIcon from "@mui/icons-material/AlignVerticalBottom";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteCellIcon from "./DeleteCellIcon";
import DeleteRowIcon from "./DeleteRowIcon";
import { TableUtil } from "../../utils/table";
import TablePopup from "./TablePopup";
import { useEditorSelection } from "../../hooks/useMouseMove";
import TableStyles from "./Styles";
import "./table.css";

const TABLE_MENUS = [
  {
    Icon: AlignHorizontalRightIcon,
    text: "Insert Columns to the Right",
    action: {
      type: "insertColumn",
      position: "after",
    },
  },
  {
    Icon: AlignHorizontalLeftIcon,
    text: "Insert Columns to the Left",
    action: {
      type: "insertColumn",
      position: "at",
    },
  },
  {
    Icon: AlignVerticalTopIcon,
    text: "Insert Row Above",
    action: {
      type: "insertRow",
      positon: "at",
    },
  },
  {
    Icon: AlignVerticalBottomIcon,
    text: "Insert Row Below",
    action: {
      type: "insertRow",
      position: "after",
    },
  },
  {
    Icon: DeleteRowIcon,
    text: "Delete Row",
    action: {
      type: "deleteRow",
    },
  },
  {
    Icon: DeleteCellIcon,
    text: "Delete Column",
    action: {
      type: "deleteColumn",
    },
  },
  {
    Icon: SettingsIcon,
    text: "Settings",
    action: {
      type: "settings",
    },
  },
  {
    Icon: DeleteForeverIcon,
    text: "Remove Table",
    action: {
      type: "remove",
    },
  },
];

const Table = (props) => {
  const { element, attributes, children, customProps } = props;
  const classes = TableStyles();
  const { readOnly } = customProps;
  const [openSetttings, setOpenSettings] = useState(false);
  const [exandTools, setExpandTools] = useState(false);
  const { bgColor, borderColor } = element;
  const editor = useSlateStatic();
  const selected = useSelected();
  const table = new TableUtil(editor);
  const tableProps = table.getTableProps();
  const [showTool] = useEditorSelection(editor);

  const handleAction =
    ({ type, position }) =>
    () => {
      Transforms.select(editor, editor.selection);
      switch (type) {
        case "insertRow":
          table.insertRow(position);
          break;
        case "insertColumn":
          table.insertColumn(position);
          break;
        case "deleteRow":
          table.deleteRow();
          break;
        case "deleteColumn":
          table.deleteColumn();
          break;
        case "remove":
          table.removeTable();
          break;
        case "settings":
          if (tableProps) {
            onSettings(true);
          }
          break;
        default:
          return;
      }
    };

  const handleExpand = () => {
    setExpandTools(!exandTools);
  };

  const ToolBar = () => {
    return selected && !showTool ? (
      <Box
        component={"div"}
        contentEditable={false}
        className={`tableToolBar ${exandTools ? "active" : ""}`}
        sx={classes.tableToolBar}
      >
        <Tooltip title={"Show Tools"} arrow onClick={handleExpand}>
          <IconButton className="toolbtn toggle">
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        {TABLE_MENUS.map(({ Icon, text, action }) => {
          return (
            <Tooltip key={text} title={text} arrow>
              <IconButton className="toolbtn" onClick={handleAction(action)}>
                <Icon />
              </IconButton>
            </Tooltip>
          );
        })}
      </Box>
    ) : null;
  };

  const onSettings = () => {
    setOpenSettings(true);
  };

  const onSave = (data) => {
    const updateData = { ...data };
    delete updateData.children;
    delete updateData.type;
    table.updateTableStyle(updateData, {
      ...tableProps,
    });
    onClose();
  };

  const onClose = () => {
    setOpenSettings(false);
  };

  return (
    <div
      style={{
        minWidth: "100%",
        maxWidth: "100%",
        position: "relative",
      }}
    >
      <TableComp
        sx={classes.table}
        style={{
          background: bgColor,
          border: `1px solid ${borderColor}`,
          width: "auto",
        }}
      >
        <TableBody {...attributes}>{children}</TableBody>
      </TableComp>
      {!readOnly && <ToolBar />}
      {openSetttings ? (
        <TablePopup
          element={tableProps?.styleProps || {}}
          onSave={onSave}
          onClose={onClose}
          customProps={customProps}
        />
      ) : null}
    </div>
  );
};

export default Table;
